<script setup lang="ts">
    import { useDateFormatter } from '@/composables/date-format';
    import type { OrderLineItemCtxOrderInformation } from '@containex/portal-backend-dto';
    import OrderItemStateChip from './OrderItemStateChip.vue';
    import { useI18n } from 'vue-i18n';

    defineProps<{
        information: OrderLineItemCtxOrderInformation;
        isRental: boolean;
    }>();

    const { t } = useI18n();
    const { dateFormatter } = useDateFormatter();
</script>
<template>
    <div class="order-information-box">
        <h3 class="text-base-bold-line-height-auto order-information-box-head">Auftrag {{ information.id }}</h3>
        <table class="embedded-table">
            <tr class="embedded-table-head">
                <td>{{ t('ACCOUNT.ORDER_DETAIL.SERIAL_NUMBER') }}</td>
                <td>{{ t('ACCOUNT.ORDER_DETAIL.STATE') }}</td>
                <td v-if="!isRental">{{ t('ACCOUNT.ORDER_DETAIL.DELIVERY_DATE') }}</td>
                <td v-else>{{ t('ACCOUNT.ORDER_DETAIL.RENTAL_PERIOD') }}</td>
            </tr>
            <tr v-for="position of information.positions" :key="position.serial_number ?? undefined">
                <td>{{ position.serial_number ?? '-' }}</td>
                <td>
                    <OrderItemStateChip v-if="position.state != null" :state="position.state" />
                    <span v-else>-</span>
                </td>
                <td v-if="!isRental" class="color-success">
                    {{
                        position.delivery_date != null
                            ? dateFormatter.formatDateTwoDigits(new Date(position.delivery_date))
                            : '-'
                    }}
                </td>
                <td v-else-if="isRental && position.rental_start != null && position.rental_end != null">
                    {{ dateFormatter.formatDateTwoDigits(new Date(position.rental_start)) }} -
                    {{ dateFormatter.formatDateTwoDigits(new Date(position.rental_end)) }}
                </td>
                <td v-else>-</td>
            </tr>
        </table>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .order-information-box {
        margin: main.$spacing-5;
        border: 1px solid main.$color-border-gray;
        border-radius: 4px;
        padding: main.$spacing-5;
    }

    .order-information-box-head {
        color: main.$color-primary-500;
        margin-bottom: main.$spacing-2;
    }

    .embedded-table {
        width: 100%;
        padding: 0;
        margin: 0;
        border-collapse: collapse;
    }

    .embedded-table tr td {
        padding-top: main.$spacing-3;
        padding-bottom: main.$spacing-3;
        color: main.$color-text;
        border-bottom: 1px solid main.$color-border-gray;
    }

    .embedded-table-head {
        color: main.$color-secondary;
    }

    .embedded-table tr:last-of-type td {
        border: 0;
    }

    .embedded-table tr td.color-success {
        color: main.$color-success-green;
    }
</style>
