<script setup lang="ts">
    import { componentStyle } from '@/styling/util/component-style';
    import Panel from 'primevue/panel';
    import { isMobile } from '@/util/breakpoints';

    defineProps<{
        title?: string;
    }>();

    const panelStyle = componentStyle({
        borderColor: '{ surface.200 }',
        borderRadius: '6px',
    });
</script>
<template>
    <Panel :dt="panelStyle" :class="{ invisible: title == null }">
        <template v-if="title != null" #header>
            <h3 class="text-base-bold-line-height-auto">{{ title }}</h3>
        </template>

        <div class="content" :class="{ 'content-mobile': isMobile }">
            <slot name="gridcontent"></slot>
        </div>
    </Panel>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    :deep(.p-panel-header) {
        background: unset;
    }

    .invisible :deep(.p-panel-header) {
        display: none;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 1ch;
    }

    .content-mobile {
        grid-template-columns: minmax(min-content, 1fr) auto;
    }
</style>
