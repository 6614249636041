<script setup lang="ts">
    import { useProductAction } from '@/composables/product';
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import Select from 'primevue/select';
    import { ProductSortField, SortDirection } from '@containex/portal-backend-dto';

    const { t } = useI18n();
    const productAction = useProductAction();

    const selectedSortOption = ref();
    const sortOptions = ref([
        { name: t('PRODUCT_LIST.SORT.DEFAULT'), field: undefined, code: SortDirection.DESC },
        { name: t('PRODUCT_LIST.SORT.PRICE_DESC'), field: ProductSortField.PRICE, code: SortDirection.DESC },
        { name: t('PRODUCT_LIST.SORT.PRICE_ASC'), field: ProductSortField.PRICE, code: SortDirection.ASC },
        { name: t('PRODUCT_LIST.SORT.NAME_DESC'), field: ProductSortField.TITLE, code: SortDirection.DESC },
        { name: t('PRODUCT_LIST.SORT.NAME_ASC'), field: ProductSortField.TITLE, code: SortDirection.ASC },
    ]);

    function changeSortOption(): void {
        //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (selectedSortOption.value.field == null) {
            productAction.resetSort();
        } else {
            //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            productAction.setSort(selectedSortOption.value.field, selectedSortOption.value.code);
        }
    }
</script>
<template>
    <Select
        v-model="selectedSortOption"
        :options="sortOptions"
        option-label="name"
        :placeholder="t('PRODUCT_LIST.SORT.PLACEHOLDER')"
        @value-change="changeSortOption"
    />
</template>
