<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { useProductAction, useProductQuery } from '@/composables/product';
    import { computed, ref, watch } from 'vue';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { Navigation } from '@/router/navigation';
    import ProductDetailsTable from '@/product/common/components/ProductDetailsTable.vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { useMarketQuery } from '@/composables/market';
    import { useI18n } from 'vue-i18n';
    import Tag from 'primevue/tag';
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { useRouter } from 'vue-router';
    import { optimalVariantForMarket } from '@/product/common/util/optimal-variant-for-market';
    import ProductImageGallery from '@/product/common/components/ProductImageGallery.vue';
    import { primaryAndOtherVariantSplit } from '@/product/common/util/primary-and-other-variant-split';
    import ProductExternalDocuments from '@/product/common/components/ProductExternalDocuments.vue';
    import RentalProductDetails from '@/product/rental/components/RentalProductDetails.vue';
    import NotFound from '@/common/components/NotFound.vue';
    import { filterProductVariantForProvisionType } from '@/util/filterProductVariantForProvisionType';
    import { isDiscountedVariant } from '@/util/variant-discount';
    import GenericBreadcrumb from '@/common/components/GenericBreadcrumb.vue';

    const props = defineProps<{
        id: string;
        variantId?: string;
    }>();

    const { t } = useI18n();
    const router = useRouter();
    const cartAction = useCartAction();
    const { currentProduct } = useProductQuery();
    const productAction = useProductAction();
    const { market } = useMarketQuery();
    const { rentalStart, rentalEnd, currentRegionId, currentZipCode } = useCartQuery();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();

    const fetchProductTask = useAsyncTask(async () => {
        await productAction.fetchProduct(props.id);
    });

    const hasWrongProvisionType = ref(false);

    const isRentalDurationSet = computed(() => rentalStart.value != null && rentalEnd.value != null);

    const checkIfVariantIdExistsOnProduct = useAsyncTask(async () => {
        const variantsForProvisionType = currentProduct.value?.variants.filter((variant) =>
            filterProductVariantForProvisionType(variant, market.value?.code, true)
        );

        hasWrongProvisionType.value = variantsForProvisionType == null || variantsForProvisionType?.length === 0;
        if (hasWrongProvisionType.value) {
            return;
        }

        const variantFromId = currentProduct.value?.variants.find((i) => i.id === props.variantId);

        if (variantFromId == null) {
            if (currentProduct.value?.variants[0]?.id != null) {
                await navigateToVariant(
                    optimalVariantForMarket(currentProduct.value?.variants, market.value?.code, true)?.id ??
                        currentProduct.value?.variants[0]?.id
                );
            }
        }
    });

    const showNotFound = computed(() => hasWrongProvisionType.value || currentProduct.value == null);

    const splitResult = computed(() =>
        primaryAndOtherVariantSplit({
            product: currentProduct.value,
            marketCode: market.value?.code,
            isRental: true,
            variantId: props.variantId,
        })
    );
    const currentVariant = computed(() => splitResult.value.primaryVariant);

    async function handleAddToCartByVariantId(productToAdd: CtxProduct, variantIdToAdd: string): Promise<void> {
        if (rentalStart.value == null || rentalEnd.value == null) {
            orderInfoSidebarAction.setProductToAdd(productToAdd);
            orderInfoSidebarAction.setIsVisible(true);
        } else {
            await cartAction.addLineItemByVariantId(productToAdd, variantIdToAdd);
        }
    }

    const isCurrentVariantDiscounted = computed(() => {
        if (currentVariant.value == null) {
            return false;
        }

        return isDiscountedVariant(
            currentVariant.value,
            true,
            rentalStart.value != null && rentalEnd.value != null,
            rentalStart.value,
            rentalEnd.value
        );
    });

    watch(currentProduct, async () => {
        if (currentProduct.value != null) {
            await checkIfVariantIdExistsOnProduct.perform();
        }
    });

    // Reload products when rental dates change because of different prices
    // Reload products when switching region (different prices)
    watch(
        [rentalStart, rentalEnd, currentRegionId],
        async () => {
            await fetchProductTask.perform();
        },
        { immediate: true }
    );

    watch(
        currentVariant,
        async () => {
            if (props.variantId != null && props.variantId !== '') {
                return;
            }

            if (currentVariant.value?.id === props.variantId) {
                return;
            }

            await router.replace({
                params: {
                    id: props.id,
                    variantId: currentVariant.value?.id,
                },
            });
        },
        { immediate: true }
    );

    async function navigateToVariant(variantId: string): Promise<void> {
        await router.push({
            name: Navigation.SalesProductDetail,
            params: {
                id: props.id,
                variantId,
            },
        });
    }
</script>

<template>
    <div class="product-detail-container">
        <template
            v-if="
                fetchProductTask.last?.isSuccessful &&
                checkIfVariantIdExistsOnProduct.last?.isSuccessful &&
                currentProduct != null &&
                !showNotFound
            "
        >
            <GenericBreadcrumb
                :back="[
                    { link: Navigation.RentalProductList, title: t('NAVIGATION.AREAS.RENT') },
                    { link: Navigation.RentalProductList, title: t('NAVIGATION.MENU_ITEM.ALL_PRODUCTS') },
                ]"
                :title="currentProduct.title ?? ''"
            ></GenericBreadcrumb>

            <div class="product-details-page">
                <div class="details-header-container">
                    <div class="details-header-left">
                        <ProductImageGallery :product="currentProduct"></ProductImageGallery>
                    </div>
                    <div class="details-header-right">
                        <div class="tag-container">
                            <Tag
                                v-if="isCurrentVariantDiscounted && currentRegionId != null && currentZipCode != null"
                                class="offer"
                                :value="t('PRODUCT.STATE.OFFER')"
                            />
                        </div>

                        <RentalProductDetails
                            :product="currentProduct"
                            :variant="currentVariant"
                            :current-region-id="currentRegionId"
                            :postal-code="currentZipCode"
                            :is-rental-duration-set="isRentalDurationSet"
                            :is-variant-discounted="isCurrentVariantDiscounted"
                            @add-to-cart="handleAddToCartByVariantId(currentProduct, $event)"
                        ></RentalProductDetails>
                    </div>
                </div>
                <div class="more-details-container">
                    <div class="more-details">
                        <ProductDetailsTable :product="currentProduct"></ProductDetailsTable>
                    </div>

                    <ProductExternalDocuments
                        v-if="currentProduct.documents_url != null"
                        :documents-url="currentProduct.documents_url"
                    ></ProductExternalDocuments>
                </div>
            </div>
        </template>
        <LoadingSpinner
            v-else-if="fetchProductTask.isRunning || checkIfVariantIdExistsOnProduct.isRunning"
        ></LoadingSpinner>
        <NotFound v-else-if="showNotFound" @redirect="router.push({ name: Navigation.RentalProductList })"></NotFound>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .product-detail-container {
        display: flex;
        flex-direction: column;
    }

    .offer {
        background-color: main.$color-discount-red;
        color: main.$color-white;
    }

    .product-details-page {
        display: flex;
        flex-flow: column;
        row-gap: main.$spacing-8;
    }

    .more-details-container {
        display: flex;
        flex-flow: column;
        row-gap: main.$spacing-8;
        align-items: center;

        .more-details {
            max-width: 792px;
            width: 100%;
        }
    }

    .tag-container {
        padding: main.$spacing-4 0;
        display: flex;
        gap: main.$spacing-4;
    }

    .details-header-container {
        grid-template-columns: 100%;
        display: grid;
        grid-gap: main.$spacing-6;
    }

    @include main.for-breakpoint-lg {
        .details-header-container {
            grid-template-columns: 70% 30%;
        }
    }
</style>
